<template>
  <section
    id="features"
    class="grey lighten-3 px-3"
  >
    <v-img
        contain
        height="200"
        color="grey"
        eager
        src="/static/liefermenu.png"
    />
    <section-header
      text="Die All-in-one Software Lösung für ihr Restaurant."
    />

    <v-container
      :mt-5="$vuetify.breakpoint.mdAndUp"
      :pt-5="$vuetify.breakpoint.mdAndUp"
      px-0
      pb-0
    >
      <v-row
        v-for="(feature, i) in features"
        :key="i"
        :class="[$vuetify.breakpoint.mdAndUp && 'my-5']"
        align="center"
        :style="{
          flexDirection: $vuetify.breakpoint.mdAndUp && i === 1 ? 'row-reverse' : 'row'
        }"
      >
        <v-col
          cols="12"
          md="6"
          class="hidden-sm-and-down"
        >
          <v-sheet
            max-width="400"
            class="mx-auto"
          >
            <v-img
              width="100%"
              height="300"
              color="grey"
              :src="feature.src"
              :lazy-src="feature.lazy"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <h3
            class="headline font-weight-regular mb-3 mt-2"
            v-text="feature.header"
          />
          <p
            class="body-1 font-weight-light"
            v-text="feature.text"
          />
        </v-col>
        <v-col
          v-if="i !== features.length - 1"
          cols="12"
          class="py-5 hidden-sm-and-down"
          order-xs="4"
        >
          <v-divider />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  export default {
    data: () => ({
      features: [
        {
          src: '/static/feature-1.png',
          lazy : '/static/feature-1-sm.png',
          header: 'Ihre eigene Webpräsenz',
          text: 'Wir helfen Ihnen dabei ihre eigene individuelle Webpräsenz aufzubauen, mit eingebauter Bestellfunktion, Online Speisekarte und vielem mehr! Weiterhin bieten wir Ihnen die Möglichkeit Angebote, Rabatte und Gutscheine herauszugeben! All dies bieten wir unter ihrer eigenen Domain als moderne Single-Page Webapplikation zur Verfügung gestellt, mit voller Unterstützung für jedes Handy, jedes Tablet und jeden Computer.'
        },
        {
          src: '/static/feature-2.png',
          lazy : '/static/feature-2-sm.png',
          header: 'Null Provision',
          text: 'Es ist ihre eigene Webseite, also zahlen Sie Null Provision an uns! Wir helfen Ihnen weiterhin mit einem maßgeschneiderten Marketing-Paket und mit einer Google-Einbindung dabei ihre neue Webseite zu bewerben. Wir bieten Ihnen den Bestellempfang per Tablet mit integriertem Bondrucker, per E-Mail oder per Fax an, damit Sie sicher jede Bestellung schnell bearbeiten können.'
        },
        {
          src: '/static/feature-3.png',
          lazy : '/static/feature-3-sm.png',
          header: 'Moderne Zahlungsmöglichkeiten',
          text: 'Wir bieten ihren Kunden die Möglichkeit schnell und einfach mit modernen Online Payment Zahlungsmöglichkeiten zu bestellen! Wir übernehmen die Integration, also müssen Sie sich um nichts kümmern und erhalten einfach das Geld!'
        }
      ]
    })
  }
</script>
